    import React, { Suspense, useState, useEffect } from "react";
    import Navbar from "./navbar";
    import { BsDiscord } from "react-icons/bs";

    const HeroSection = () => {
    const [days, hours, minutes, seconds] = useCountdown(new Date(2024, 0 , 26, 0, 0, 0));

    const timer = [
        {
        id: "cd-day",
        value: days < 0 ? 0 : days,
        unit: "Days",
        },
        {
        id: "cd-hour",
        unit: "Hrs",
        value: hours < 0 ? 0 : hours,
        },
        {
        id: "cd-minute",
        unit: "Mins",
        value: minutes < 0 ? 0 : minutes,
        },
        {
        id: "cd-second",
        unit: "Secs",
        value: seconds < 0 ? 0 : seconds,
        },
    ];

    return (
     
        <div
            className="w-screen bg-cover inset-0 pt-0 pb-0"
            style={{

                backgroundImage:
                    'url("/assets/locus_bg.jpg")',
            }}
        >
            <div
                className='inset-0 pt-36 pb-24'
                style={{
                    background:
                        "linear-gradient(0deg, rgba(23, 3, 61, 0.8) 100%, rgba(94, 61, 140, 0.00) 100%)",
                }}
            >
                <Navbar />
                <div className='flex justify-center items-center pt-15 flex-col h-full w-full gap-20 '>
                    <div>
                        <h1 className='text-white text-3xl text-center font-semibold sm:text-4xl md:text-5xl lg:text-6xl'>
                            THE NATIONAL <br />{" "}
                            <span
                                style={{
                                    color: "#D897FE",
                                }}
                            >
                                TECHNOLOGICAL
                            </span>{" "}
                            FESTIVAL
                        </h1>
                        <p className='text-center text-white text-base md:text-lg lg:text-xl'>
                            "Digital Transformation : Redefining Now, Inventing the Next"
                        </p>
                    </div>
                    <div className="flex w-[25rem] mx-auto h-auto justify-evenly" id="countdown">
                {timer.map((item) => {
                    return (
                      <div
                        className="bg-[#F9F7F7] w-[4rem] rounded-md h-[5rem] flex flex-col items-center"
                        key={item.id}
                      >
                        <span
                          className="text-[#D897FE] text-[2rem] font-bold"
                          id={item.id}
                        >
                          <Suspense fallback={null}>{item.value}</Suspense>
                        </span>
                        <p className="uppercase text-[#542361] font-bold">
                          {item.unit}
                        </p>
                      </div>
                    );
                })} 
                </div>
                    <div>
                        <a
                            target="_blank"
                            href="https://discord.gg/WZbC97PP"
                            style={{ backgroundColor: "#F7BD39" }}
                            className='p-2 rounded-2xl flex items-center gap-2 '
                        >
                            {" "}
                            <BsDiscord />
                            Join Discord
                        </a>
                    </div>
                    <div className="text-white text-lg text-justify px-10">
                    LOCUS is a non-profit organization led by undergraduate students of Electrical, Electronics, and Computer Engineering departments from IOE, Pulchowk Campus. Since its inception in 2003, LOCUS has been providing a platform for young innovators from schools and colleges all over Nepal to showcase their skills and knowledge. LOCUS 2025 marks the 21st edition of the National Level Technological Exhibition. All its activities are guided by a single compass: fostering collaboration, promoting individual development and facilitating innovation.
                    </div>

                </div>
         
            </div>
        </div>
    );
    };

    export default HeroSection;


    const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
    };

    const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
    };
