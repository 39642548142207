import ReCAPTCHA from "react-google-recaptcha";
import React from 'react';


function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

function Contact() {

    let [formData, setFormData] = React.useState({ name: "", email: "", message: "" });
    let [isLoading, setLoading] = React.useState(false);
    let [message, setMessage] = React.useState({});
    let [showMessage, setShowMessage] = React.useState(false);

    let handleInputChange = (e) => {
        setShowMessage(false);
        setFormData({
            ...formData, [e.target.name]: e.target.value,
        })
    }
    const recaptchaRef = React.useRef();

    const submitForm = async (e) => {
        if(!isValidEmail(formData.email)){
            setMessage({success: false, message: "Invalid Email."});
            setShowMessage(true);
            return;

        }
        setLoading(true);
        const token = await recaptchaRef.current.executeAsync();

        let response = await fetch("https://script.google.com/macros/s/AKfycbwH9FOIUrSXH4iv9AOJBJIbXJhkss5WhUiL3pNMRpdaNMiN5JT8fzMy9uZiv4C8qtDo/exec",
            {
                redirect: "follow",
                method: "POST",
                body: JSON.stringify({
                    ...formData,
                    token: token
                })
            })

        const jsonRes = await response.json();
        console.log(jsonRes);
        if (jsonRes) {
            setMessage(jsonRes);
            setShowMessage(true);
            console.log(jsonRes.success);
        };
        setLoading(false);
    }
    return (
        <>
            <div id="Contact"></div>
            <section className="mt-20">
                <h1 className="text-[#D897FE] font-bold text-[48px] text-center mb-16">CONTACT US</h1>
                <section className="flex md:flex-row flex-col items-center mx-12">
                    <div className="flex md:w-[55%] p-3 md:border-r-2 border-grey-500">
                        <section className="flex-auto w-[30%] h-[90%] flex flex-col gap-3 mb-4">
                            <div className="w-[100%] h-[100%] mt-6">
                                <img className="inline-block pr-8" src={"/assets/location.png"} alt="location.png" />
                                <p className="text-[#7A7878] text-xl inline-block">Pulchowk Engineering Campus, Pulchowk</p>
                                <hr className="h-px my-3 bg-gray-200 border-0"></hr>
                            </div>
                            <div className="w-[100%] h-[100%] mt-6">
                                <img className="inline-block pr-8" src={"/assets/phone.png"} alt="phone.png" />
                                <p className="text-[#7A7878] text-xl inline-block">+977 9860620870</p>
                                <hr className="h-px my-3 bg-gray-200 border-0"></hr>
                            </div>
                            <div className="w-[100%] h-[100%] mt-6">
                                <img className="inline-block pr-8" src={"/assets/mail.png"} alt="mail.png" />
                                <p className="text-[#7A7878] text-xl inline-block">locus@ioe.edu.np</p>
                                <hr className="h-px my-3 bg-gray-200 border-0"></hr>
                            </div>
                            <div className="w-[100%] h-[100%] mt-6 justify-center flex">
                                <a href="https://www.facebook.com/locus.ioe/" target="_blank">
                                    <img className="inline-block mr-8 w-14" src={"/assets/facebook.png"} alt="facebook.png" />
                                </a>
                                <a href="https://twitter.com/locus_ioe" target="_blank">
                                    <img className="inline-block mr-8 w-14" src={"/assets/twitter.png"} alt="facebook.png" />
                                </a>
                                <a href="https://www.tiktok.com/@locus_ioe" target="_blank">
                                    <img className="inline-block mr-8 w-14" src={"/assets/tiktok.png"} alt="facebook.png" />
                                </a>
                                <a href="https://www.instagram.com/locus_ioe" target="_blank">
                                    <img className="inline-block mr-8 w-14" src={"/assets/instagram.png"} alt="facebook.png" />
                                </a>
                            </div>
                        </section>
                    </div>
                    <div className="flex md:w-[45%] border-t-2 border-grey md:border-none pt-6">
                        <section className="flex-auto flex justify-around">
                            <div className="md:w-[90%] flex flex-col justify-center text-2xl gap-10 overflow-hidden">
                                <input className="bg-[#D9D9D9] rounded-lg p-4  placeholder-white" placeholder="Your Name" type="text" name="name" onChange={handleInputChange} />
                                <input className="bg-[#D9D9D9] rounded-lg p-4 placeholder-white" placeholder="Your Email" type="email" name="email" onChange={handleInputChange} />
                                <textarea className="bg-[#D9D9D9] rounded-lg p-4 placeholder-white" placeholder="Your Message" type="text" name="message" onChange={handleInputChange} />
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey="6LdxHiUpAAAAAHXpB3nQHyE7z8Te4yXGP81btaTm"
                                />
                                {showMessage ?
                                    <div className={`p-2 rounded-lg text-center text-md " ${message.success ? "bg-green-400" : "bg-red-400"}`}>
                                        <p className={message.success ? "text-green-800" : "text-red-800"}>{message.message}</p>
                                    </div>
                                    : <></>
                                }
                                <button className="bg-[#7D5983] text-center text-white rounded-lg p-3 w-60" onClick={submitForm} disabled={isLoading}>{isLoading ? "Sending..." : "Submit"}</button>
                            </div>
                        </section>
                    </div>
                </section>
                <section>
                    <div className="bg-[#5A2565] text-white text-center py-4 mt-16">
                        <p className="text-lg">©Copyright <span className="font-medium">LOCUS</span>. All Rights Reserved.</p>
                        {/* <p>Maintainers</p> */}
                    </div>
                </section>
            </section>
        </>
    )
}

export default Contact;