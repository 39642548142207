import Hero from './component/hero';
import Events from './component/Events';
import Committee from './component/committee';
import Contact from './component/contact';
import Sponsors from './component/sponsors';
import { Routes, Route } from 'react-router-dom';
import EventSection from './component/EventSection';
import Verify from './component/Verify';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/verify" element={
          <Verify />
        }
        />
        <Route path="/" element={
          <>
            <Hero />
            <EventSection />
            {/* <Sponsors /> */}
            <Committee />
            <Contact />
          </>
        }
        />
      </Routes>
    </div>
  );
}

export default App;
