import { useSwiper } from "swiper/react";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

function SwiperButtons() {
  const swiper = useSwiper();
  return (
    <div className='p-4 text-right mt-20 '>
      <button
        onClick={() => swiper.slidePrev()}
        className='p-4 text-lg bg-custom-color  text-white hover:bg-green-600 hidden md:inline'
      >
        <FaArrowAltCircleLeft />
      </button>
      <button
        onClick={() => swiper.slideNext()}
        className='p-4 ml-4 text-lg bg-custom-color text-white hover:bg-green-600 hidden md:inline'
      >
        <FaArrowAltCircleRight />
      </button>
    </div>
  );
}

export default SwiperButtons;
