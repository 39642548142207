import React from "react";
import Events from "./Events";

function EventSection() {
  return (
    <section
      id='Events'
      className=' my-20  '
    >
      <h1 className='text-5xl text-center font-bold text-[#D897FE]'>Events</h1>
      <div className='w-5/6 mx-auto my-10 '>
        <Events />
      </div>
    </section>
  );
}

export default EventSection;
