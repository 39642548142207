// LocusCardPreview.js

import React from "react";
import { Link } from "react-router-dom";

const truncateText = (text, limit) => {
  const words = text.split(" ");
  if (words.length > limit) {
    return words.slice(0, limit).join(" ") + "...";
  }
  return text;
};

const LocusCardPreview = ({ event }) => {
  const { id, title, imageUrl, description } = event;
  const truncatedDescription = truncateText(description, 30);

  return (
    <div className='max-w-sm rounded overflow-hidden shadow-lg  height_of_card'>
      <img
        className='w-full h-48 object-cover object-center'
        src={imageUrl}
        alt={title}
      />
      <div className='px-6 py-4'>
        <div className='font-bold  mb-2 text-center text-lg'>{title}</div>
        <p className='text-gray-700 mb-2 text-justify'>{truncatedDescription}</p>
        <Link
          to={`#`}
          className='bg-custom-color  text-white py-2 px-4 rounded mt-5 block text-center'
        >
          Know More
        </Link>
      </div>
    </div>
  );
};

export default LocusCardPreview;
