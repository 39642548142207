import { useEffect, useState } from 'react';
const { jsPDF } = require('jspdf');


function PdfFromImage({ imageUrl }) {
    const [pdfUrl, setPdfUrl] = useState(null);

    const handleClick = () => {
        // Create a PDF document
        const image = new Image();
        image.src = imageUrl;

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [image.width, image.height],
        });

        // Load the image and add it to the PDF
        image.onload = () => {
            doc.addImage(image, 'JPEG', 0, 0, image.width, image.height);
            doc.save('Certificate.pdf');
        };

    };

    return (
        // <a href={handleClick}>
        <button
            id="download-button"
            style={{ margin: "1rem", borderWidth: "4px" }}
            type="download-button"
            className='bg-custom-color  text-white py-2 px-4 rounded mt-5 block text-center'
            onClick={handleClick}
        >
            Download PDF
        </button>
        // </a>

    );
}

export default PdfFromImage;