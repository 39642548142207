import { useState } from "react";
import members from '../data/committee.json'
import { Link } from "react-router-dom";

function Committee() {
    return (
        <div id="Committee" className="w-[95vw] mx-auto flex items-center  justify-center flex-col md:w[90vw] xl:max-w-[80vw] my-20">
            <h1 className="text-[2rem] text-[#FF01B8] my-8 text-center leading-7 py-3 font-bold md:text-[3rem]">
                COMMITTEE MEMBERS
            </h1>
            <div className="flex  gap-20 flex-wrap my-3">
                {members.map((member) => (
                    <SingleTile
                        key={member.id}
                        name={member.name}
                        designation={member.designation}
                        image={member.imageUrl}
                        facebook={member.facebook}
                        instagram={member.instagram}
                        linkedin={member.linkedin}
                        twitter={member.twitter}
                    />
                ))}
            </div>
        </div>
    );
}

const SingleTile = (props) => {
    const { name, image, designation, facebook, instagram, linkedin, twitter } = props;

    const [hover, setHover] = useState(false);

    console.log(facebook);
    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="relative min-w-[250px] max-w-[600px] mx-auto h-auto w-[300px]  md:min-w-[300px] flex-1 overflow-hidden"
        >
            <div className="w-full h-80 overflow-hidden justify-center items-center ">
                <div
                    className={` max-w-[120%] h-full bg-center bg-contain bg-no-repeat ${hover ? "scale-105" : ""
                        } transition-all`}
                    style={{

                        backgroundImage: `url(${image})`
                    }}
                    // src={image}
                    alt=""
                ></div>
            </div>
            <div
                className={`  ${hover ? "mt-[-100px] h-[100px]" : "mt-[-50px] h-[50px]"
                    } absolute z-10 w-full bg-[#582965B2] flex flex-col items-center text-white transition-all `}
            >
                <h1 className="text-[20px] font-bold leading-24 ">{name}</h1>
                <h2 className="text-[14px] italic leading-3">{designation}</h2>
                <div className="flex gap-4 mt-3">
                    {facebook ? <a href={facebook} target="_blank">
                        <img src="/assets/facebook-logo-skeleton.png" alt="" />
                    </a> : <></>
                    }
                    {instagram ?
                    <Link to={instagram} target="_blank">
                        <img src="/assets/instagram-logo-skeleton.png" alt="" />
                    </Link> : <></>
                    }
                    {linkedin ?
                    <Link to={linkedin} target="_blank">
                        <img src="/assets/linkedin-logo-skeleton.png" alt="" />
                    </Link> : <></>
                    }
                    {
                        twitter ?
                    <Link to={twitter} target="_blank">
                        <img src="/assets/twitter-logo-skeleton.png" alt="" />
                    </Link> : <></>
                    }

                </div>
            </div>
        </div>
    );
};

export default Committee;
