import Axios from "axios";

import React, { useEffect, useState } from "react";
import PdfFromImage from "../utils/generatepdf";

const BASE_URL = "https://api.locus.com.np";

const CertificateExists = ({image}) => {
  const image_path = `${BASE_URL}${image}`;
  console.log(image_path);
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location;
  const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  const copyLink = () => {
    navigator.clipboard.writeText(currentUrl);
    setCopied(true);
  };

  return (
    <section>
      <div id="do-exist" style={{ display: "block" }}>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "0.5rem", paddingTop: "1rem" }}
        >
          <img
            width="95%"
            height="auto"
            src={image_path}
            style={{ border:"1px solid black", maxWidth: "800px" }}
            alt="Certificate"
            id="img"
          />
        </div>
        <div
          style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", padding: "1rem" }}
        >
          <PdfFromImage imageUrl={image_path} />
          <button
            id="copy-button"
            style={{ margin: "1rem", borderWidth: "4px" }}
            type="copy-link"
            className='bg-custom-color  text-white py-2 px-4 rounded mt-5 block text-center'
            onClick={copyLink}
          >
            {copied ? "Copied" : "Copy Link"}
          </button>
          <a href={shareUrl} target="blank">
            <button
              id="share-button"
              style={{ margin: "1rem", borderWidth: "4px" }}
              type="copy-link"
              className='bg-custom-color  text-white py-2 px-4 rounded mt-5 block text-center'
            // onclick={share()}
            >
              Share on Linkedin
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};


const Verify = () => {
  const [certificatePath, setCertificatePath] = useState(null);
  const [message, setMessage] = useState("Loading...")

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let certificateId = params.get('id');
    const data = Axios.get(
      `${BASE_URL}/detail-from-image/${certificateId}`
    ).then(data => {
      setMessage(false);
      setCertificatePath(data.data.image);
    }).catch(err => {
      setMessage("Certificate not found.")
    });

  }, [])
  return (
    message ? <><div className="text-5xl grid h-screen place-items-center">{message}</div></> : (
      <>
        <CertificateExists image={certificatePath}/>
      </>
    )
  );
};

export default Verify;
