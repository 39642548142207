import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

function Navbar() {
  const navItems = [
    "",
    "Home",
    "Events",
    "Sponsors",
    "Committee",
    "Contact",
  ];

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <nav className='absolute top-1 left-1 right-0 flex items-center justify-between px-5 '>
      <div className='flex-shrink-0'>
        <img
          src='/assets/logos/white.png'
          alt='Logo'
          className='h-12 w-auto sm:h-20 '
        />
      </div>

      <div className='flex items-center gap-3 flex-1 justify-end'>
        <div
          className='lg:hidden cursor-pointer order-last'
          onClick={toggleMobileMenu}
        >
          <svg
            className='w-6 h-6 text-white'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h16m-7 6h7'
            ></path>
          </svg>
        </div>

        <ul
          className={`${
            isMobileMenuOpen
              ? "flex flex-col space-y-4 absolute top-0 left-0 right-0 bg-purple-500 justify-center items-center p-3   "
              : "hidden lg:flex "
          } lg:flex space-x-6 flex-1 justify-end text-lg mr-4`}
        >
          {navItems.map((item) => (
            <li
              className='text-white cursor-pointer lg:text-sm xl:text-lg bg-purple-800 w-2/3 text-center  lg:bg-inherit lg:w-fit'
              key={item}
            >
              <a href={"#" +item}>{item}</a>
            </li>
          ))}

          <AiFillCloseCircle
            onClick={() => {
              setMobileMenuOpen(false);
            }}
            className='text-white text-2xl cursor-pointer absolute top-0 right-5 lg:hidden'
          />
        </ul>

        {/* <div className='flex space-x-4 text-xs'>
          <button className='border border-white text-white px-2 py-2 rounded-full sm:px-3 sm:text-base'>
            Gallery
          </button>
          <button className='border border-white text-white px-2 py-2 rounded-full sm:px-3 sm:text-base'>
            Register a Project
          </button>
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar;
