import events from "../data/events.json";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";
import LocusCardPreview from "./EventCard";
import SwiperButtons from "./swiperButton";

const Events = () => {
  return (
    <Swiper
      pagination={true}
      modules={[Pagination]}
      className='mySwiper'
      breakpoints={{
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
    >
      {events.map((event) => {
        return (
          <SwiperSlide key={event.id}>
            <LocusCardPreview event={event} />
          </SwiperSlide>
        );
      })}
      <SwiperButtons />
    </Swiper>
  );
};

export default Events;
